import axios from 'axios';

export default class DbManager {
  static mURL = 'http://127.0.0.1:11000';

  static async POST(url, body) {
    try {
      const response = await axios.post(this.mURL + url, body, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        }
      });

      let data = response.data;

      if (data.length === 0) {
        data = false;
      }

      return data;
    } catch (error) {
      console.error(`HTTP error!`, error.response || error);
      throw error;
    }
  }

  static async GET(url) {
    try {
      const response = await axios.get(this.mURL + url, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        }
      });

      return response.data;
    } catch (error) {
      console.error('Failed to load data:', error.response || error);
      throw error;
    }
  }
}
