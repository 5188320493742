import React, { useState } from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import Dashboard from 'layouts/dashboard/Dashboard';
import Sidebar from "components/sidenavbar/SideNavbar";
import MiniMizedNav from "components/sidenavbar/minimizedNav/MiniMizedNav"
import Table from 'components/table/TableDisplay';
import Login from 'layouts/login/Login';
import DetailByStores from 'layouts/detailbystores/DetailByStores';

function Authenticated() {
  const isBigScreen = useMediaQuery({ query: '(min-width: 600px)' })
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <>
                                         
      {location.pathname !== '/login' && (isBigScreen ? <Sidebar /> : <MiniMizedNav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />)}
      <div className={`main-content ${menuOpen ? "low-opacity" : ""}`}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/about" element={<Table />} />
          <Route path="/dashboard" element={<DetailByStores />} />
          <Route path="/stores/hcq" element={<DetailByStores />} />
          <Route path="/stores/hdn" element={<DetailByStores />} />
          <Route path="/stores/hll" element={<DetailByStores />} />
          <Route path="/stores/hrm" element={<DetailByStores />} />
          <Route path="/stores/hcc" element={<DetailByStores />} />
          <Route path="/stores/hpc" element={<DetailByStores />} />
          <Route path="/stores/hdb" element={<DetailByStores />} />
          <Route path="/stores/eub" element={<DetailByStores />} />
          <Route path="/login" element={<Login />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </>
  )
}

export default Authenticated;
