import { useState, useEffect } from "react";
import DbManager from "globalApi/DbManager";

const useSalesDetailsByStores = () => {
  const [data, setData] = useState({});
  const [isSalesDetailsByStoresLoading, setIsSalesDetailsByStoresLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
        setIsSalesDetailsByStoresLoading(true);
      const resp = await DbManager.GET("/achieve/salesDetails");
      setData({
        store: resp.map(item => item.store.trim()),
        target: resp.map(item => parseInt(item.target.trim(), 10)),
        achievement: resp.map(item => parseInt(item.achievement.trim(), 10)),
        achRate: resp.map(item => parseInt(item.achRate.trim(), 10)),
      });
    setIsSalesDetailsByStoresLoading(false);
    };
    fetchData();
  }, []);
  return { data, isSalesDetailsByStoresLoading };
};

export default useSalesDetailsByStores;
