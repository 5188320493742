import TableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import ProfileIndIcon from '@mui/icons-material/AssignmentInd';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import LoginIcon from '@mui/icons-material/Login';

export const SidebNavlist = [
    {
        icon: DashboardOutlinedIcon,
        heading: "Dashboard",
    },
    {
        icon: ProfileIndIcon,
        heading: "Profile",
    },
    // {
    //     icon: ChartOutlinedIcon,
    //     heading: "Statics"
    // },
    {
        icon: LoginIcon,
        heading: "Login"
    },
];