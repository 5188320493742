import { useEffect, useState } from 'react';
import './App.css';
import Authenticated from "./layouts/authenticated/Authenticated";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import axios from 'axios';

function App() {
  return (
    <div className="App">
      <div className='AppGlass'>
        <BrowserRouter>
          <Routes>
            <Route path='/*' element={<Authenticated />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
