import { Chart } from "react-google-charts";
import "./chart.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

function ChartDisplay(props) {
  const reformattedData = reformatData(props.data);

  const reformattedProps = {
    ...props,
    data: reformattedData,
  };
  return <ExpandedChart param={reformattedProps} />;
}

function reformatData(data) {
  const keys = Object.keys(data);
  const header = keys;
  const result = [header];
  const numRows = data[keys[0]].length;

  for (let i = 0; i < numRows; i++) {
    const row = [];

    keys.forEach((key) => {
      let value = data[key][i];
      if (key === 'target' || key === 'achievement' || key === 'achRate') {
        value = value / 100;
      }
      row.push(value);
    });
    result.push(row);
  }
  return result;
}

function ExpandedChart({ param }) {
  function saveAsPDF() {
    const input = document.getElementById("my-chart");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "letter");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("chart.pdf");
    });
  }

  return (
    <div
      className="expandedChart"
      style={{
        background: param.color.background,
        boxShadow: param.color.boxShadow,
      }}
    >
      <FileDownloadOutlinedIcon className="download" onClick={saveAsPDF} />

      <span>Sales Achievement by Stores</span>
      <div className="chartSection" id="my-chart">
        <Chart
          chartType={param.chartType}
          width={param.width}
          height={param.height}
          data={param.data}
          options={param.options}
        />
      </div>
    </div>
  );
}

export default ChartDisplay;
