import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Box from "@mui/material/Box";
import Table from "components/table/TableDisplay";
import Widget from "components/widget/Widget";
import Grid from "@mui/material/Grid";
import useTotalAchieve from "./data/widgetData/TotalAchieve";
import Chart from "components/chart/ChartDisplay";
import useSalesDetails from "./data/tableData/DailySalesData";
import useSalesDetailsByStores from "./data/chartData/salesAchByStores";

const barChartProps = {
  chartType: "Bar",
  width: "100%",
  height: "100%",
  color: {
    background: "#49a3f1",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
};

const achieveDefault = {
  title: "Total Sales Achievement",
  color: "#49a3f1",
};

const marginDefault = {
  title: "Total Sales Margin",
  color: "#66BB6A",
};

//Grid-size: - xs: > 0px; - sm: > 600px; - md: > 960px; - lg: > 1280px; - xl: > 1920px;
const Dashboard = () => {
  const achieve = useTotalAchieve();
  const margin = useTotalAchieve();

  const achieveData = {
    ...achieveDefault,
    ...achieve,
  };

  const marginData = {
    ...marginDefault,
    ...margin,
  };

  const { data: dailySalesDetails, isSalesDetailsLoading } = useSalesDetails();
  const { data: salesDetailsByStores, isSalesDetailsByStoresLoading } = useSalesDetailsByStores();

  if (isSalesDetailsLoading || isSalesDetailsByStoresLoading) {
    return <div>Loading...</div>;
  }

  const barChartData = {
    ...barChartProps,
    data: salesDetailsByStores,
  }
  
  return (
    <div className="dashboard">
      <Box className="boardBox">
        <h1>Sales Overview</h1>
        <Box>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={12} md={6} style={{ flexGrow: 1 }}>
              <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12} lg={6} md={12} style={{ flexGrow: 1 }}>
                  <Widget data={achieveData} />
                </Grid>
                <Grid item xs={12} lg={6} md={12} style={{ flexGrow: 1 }}>
                  <Widget data={marginData} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ flexGrow: 1 }}>
              <span style={{ float: "left", fontWeight: "bold" }}>
                Daily Sales Details
              </span>
              <Table isClickable={false} tableData={dailySalesDetails} />
            </Grid>
            <Grid item xs={12} sm={10} style={{ flexGrow: 1 }}>
              <Chart {...barChartData} />
            </Grid>
            <Grid item xs={12} sm={8} style={{ flexGrow: 1 }}>
              <span style={{ float: "left", fontWeight: "bold" }}>
                Sales Detail by Stores
              </span>
              <Table isClickable={true} tableData={salesDetailsByStores} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
