import React from 'react';
import { Link } from 'react-router-dom';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Logo from '../../../img/logo.png';
import './minimizedNav.css';

function MiniMizedNav({ menuOpen, setMenuOpen }) {

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <nav className='mininav'>
            <img src={Logo} alt="Logo" />
            <ul className={menuOpen ? "show-menu" : ""}>
                <Link className='main'>
                    <li onClick={closeMenu}>Dashboard</li>
                </Link>
                <Link className='hcq' >
                    <li onClick={closeMenu}>Coquitlam</li>
                </Link>
                <Link className='hdn'>
                    <li onClick={closeMenu}>Downtown</li>
                </Link>
                <Link className='hll'>
                    <li onClick={closeMenu}>Langley</li>
                </Link>
                <Link className='hrm'>
                    <li onClick={closeMenu}>Richmond</li>
                </Link>
                <Link className='hcc'>
                    <li onClick={closeMenu}>Coq_Center</li>
                </Link>
                <Link className='hpc'>
                    <li onClick={closeMenu}>Port_Coq</li>
                </Link>
                <Link className='hdb'>
                    <li onClick={closeMenu}>Dunbar</li>
                </Link>
                <Link className='eub'>
                    <li onClick={closeMenu}>Ubc</li>
                </Link>
            </ul>
            <button 
                className='menu-btn'
                onClick={() => setMenuOpen(!menuOpen)}
            >
                {menuOpen ? <ClearOutlinedIcon /> : <MenuOutlinedIcon />}
            </button>
        </nav>
    )
}

export default MiniMizedNav;
