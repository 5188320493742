import DbManager from "globalApi/DbManager";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleIputChange = (event) => {
        const { name, value } = event.target;
        
        if(name === 'username'){
            setUsername(value);
        } else if (name === 'password'){
            setPassword(value);
        }
    }

    const handleLogin = async (event) => {
        event.preventDefault();

        const result = await DbManager.POST('/employee/userInfo', {epNo: username, password: password});

        if(result)
        {
            navigate("/dashboard");
        }
        else
        {
            alert("Please check your username");
        }
        
    }

    return (
        <div className="login-container">
            <div className="login-label active">Sign In</div>
            <form className="login-form">
                <input
                    className="login-input"
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={username}
                    onChange={handleIputChange}
                />
                <input
                    className="login-input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={handleIputChange}
                />
                <button className="login-button active" onClick={handleLogin}>Login</button>
            </form>
        </div>
    );
}

export default Login;