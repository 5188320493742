import React from 'react';
import './widget.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Widget = ({ data }) => {
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(value);
      };

    return (
        <div className="widget" style={{ background: "#ffff" }}>
            <div className="title-section">
                <span className="title">{data.title}</span>
            </div>
            <div className="content-section" >
                <div className="left">
                    <span className="monthly-goal">{formatCurrency(data.goal)}</span>
                    <span className="monthly-total">{formatCurrency(data.total)}</span>
                </div>
                <div className="right">
                    <CircularProgressbar
                        value={data.value}
                        text={`${data.value}%`}
                        styles={buildStyles({
                            textColor: data.color,
                            pathColor: data.color,
                            trailColor: '#edeef0',
                        })}
                    />
                </div>
            </div>
        </div>
    )
}

export default Widget;
