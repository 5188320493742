import React, { useState } from "react";
import './sidenavbar.css';
import Logo from '../../img/logo.png';
import { SidebNavlist } from "./sidenavlist";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { PAGES } from 'globalApi/EnumType';

const SideNavbar = () => {
    const [selected, setSelected] = useState(0);

    const navigate = useNavigate();

    const movePage = async (index) => {
        setSelected(index);

        switch(index)
        {
            case PAGES.DASHBOARD:
                navigate("/");
                break;
            case PAGES.PROFILE:
                break;
            case PAGES.LOGIN:
                navigate("/login");
                break;
            default:
                navigate("/about");
        }
    };

    return (
        <div className="sidebar">
            <div className="logo">
                <img src={Logo} />
                <span>
                    <span><strong>HMART</strong></span>
                </span>
            </div>
            <div className="menu">
                {SidebNavlist.map((item, index) => {
                    return (
                        <div className={selected === index ? "menuItem active" : "menuItem"}
                            key={index}
                            onClick={() => movePage(index)}
                        >
                            <item.icon />
                            <span>
                                {item.heading}
                            </span>
                        </div>
                    );
                })}
                <div className="menuItem">
                    <LogoutIcon />
                    <span>Sign out</span>
                </div>
            </div>
        </div>
    );
}

export default SideNavbar;